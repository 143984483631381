import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MarkdownModule } from 'ngx-markdown';
import { MomentModule } from 'ngx-moment';
import { AppRoutingModule } from './app-routing.module';
import { ChaptersModule } from './chapters/chapters.module';
import { DEFAULT_VALUES } from './common/constants/default-values';
import { MedsurfCommonModule } from './common/medsurf-common.module';
import { environment } from '../environments/environment';
import defaultValues from './common/constants/models/defaultvalues.json';
import {
  IndexState,
  NavigationState,
  SlideState,
  SearchState,
  UserState,
  ConstantState,
  ErrorState,
  MenuState,
  MetadataState,
  TrainingState,
  AuthState,
  WsState,
  AlertState,
  MediaState,
  DetachedState,
  SharedHistoryState,
  TextExportState,
  ReleaseNotesState
} from '@medsurf/state';
import { SETTINGS } from '@medsurf/services';
import { ChangelogComponent } from './changelog/changelog.component';
import { DocumentationComponent } from './documentation/documentation.component';
import { AppComponent } from './app.component';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsWebsocketPluginModule } from '@ngxs/websocket-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { MedsurfLibModule } from '@medsurf/module';
import { AdminModule } from './admin/admin.module';
import { SearchModule } from './search/search.module';
import { SlideDetailModule } from './slide-detail/slide-detail.module';
import { TokenInterceptor } from './common/interceptors/token.interceptor';
import { UserModule } from './user/user.module';
import { createTranslateLoader } from './translate.loader';
import {
  AnnotationEntityState,
  AnnotationGroupEntityState,
  AnnotationLabelEntityState,
  AnnotationSourceEntityState,
  AnnotationTargetEntityState,
  ChoiceEntityState,
  FreeFormStyleEntityState,
  HistoryControlState,
  ImageEntityState,
  KeymapColumnEntityState,
  KeymapStyleEntityState,
  MediaEntityState,
  MetadataEntityState,
  ModalityEntityState,
  NodeEntityState,
  NoteEntityState,
  PageEntityState,
  PointOfInterestEntityState,
  QuestionEntityState,
  SlideLayerEntityState,
  StainEntityState,
  UserEntityState,
  GeneralControlState
} from '@medsurf/flat-states';
import { NgxsHistoryPluginModule } from '@medsurf/flat-plugins';
import { FLAT_SETTINGS } from '@medsurf/flat-services';

@NgModule({
  imports: [
    NgxsModule.forRoot([
      // Flat States
      HistoryControlState,
      AnnotationEntityState,
      AnnotationGroupEntityState,
      AnnotationLabelEntityState,
      AnnotationSourceEntityState,
      AnnotationTargetEntityState,
      ChoiceEntityState,
      FreeFormStyleEntityState,
      ImageEntityState,
      KeymapColumnEntityState,
      KeymapStyleEntityState,
      MediaEntityState,
      MetadataEntityState,
      ModalityEntityState,
      NodeEntityState,
      NoteEntityState,
      PageEntityState,
      PointOfInterestEntityState,
      QuestionEntityState,
      SlideLayerEntityState,
      StainEntityState,
      UserEntityState,
      GeneralControlState,
      // Old States
      AuthState,
      MenuState,
      IndexState,
      NavigationState,
      SearchState,
      SlideState,
      UserState,
      ConstantState,
      ErrorState,
      MetadataState,
      TrainingState,
      WsState,
      AlertState,
      MediaState,
      DetachedState,
      SharedHistoryState,
      TextExportState,
      ReleaseNotesState,
    ], {
      selectorOptions: {
        // TODO suppressErrors: false,
        injectContainerState: false,
      }
    }),
    /* TODO disable websockets */
    NgxsWebsocketPluginModule.forRoot({
      url: environment.config.websocketUrl
    }),
    /**/
    NgxsStoragePluginModule.forRoot({
      key: ['auth.token', 'menu']
    }),
    NgxsHistoryPluginModule.forRoot({}),
    /** /
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
      actionSanitizer: (action) => action.dirtyNode ? { ...action, dirtyNode: '<<LONG_BLOB>>' } : action,
      stateSanitizer:  (state) => state.dirtyNodes ? { ...state, dirtyNodes: '<<LONG_BLOB>>' } : state
    }),
    /**/
    MedsurfLibModule,
    MedsurfCommonModule,
    AdminModule,
    SearchModule,
    UserModule,
    ChaptersModule,
    SlideDetailModule,
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    MarkdownModule.forRoot(),
    NgbModule,
    MomentModule
  ],
  providers: [
    { provide: SETTINGS, useValue: environment.config },
    { provide: FLAT_SETTINGS, useValue: environment.config },
    { provide: DEFAULT_VALUES, useValue: (<any>defaultValues).fields },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  ],
  declarations: [
    AppComponent,
    ChangelogComponent,
    DocumentationComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
